var $ = require('jquery');

module.exports = {
    init: function() {
        var $datepickers = $('.js-datepicker');
        if ( !$datepickers.length ) {
            return;
        }

        require.ensure([], function(require) {
            require('jquery-ui');

            $datepickers.find('input').each(function(i, input) {
                $(input).datepicker({
                    dateFormat: 'dd-mm-yy'
                });
            });
        });
    }
};
