var $ = require('jquery');
var domready = require('domready');

var FHRedactor = require('fh-redactor/build/fhRedactorIII')
require('fh-redactor/lib/redactorIII/scss/redactor.scss');

document.querySelectorAll('[data-tool~=fh-redactor]').forEach((element) => {
    new FHRedactor(element, {
        plugins: ['table', 'fullscreen', 'alignment']
    });
});

domready(function () {
    $('body').removeClass('no-js').addClass('js');
    $('a[rel="external"]').on('click', function(e) {
        $(this).attr('target', '_blank');
    });

    bootstrapPlugins.init();

    links.init();
    tables.init();
    blocks.init();
    forms.init();
    sort.init();
    fhConfirm.init();

    select2.init();
    iframes.setup();
    twitter.init();
    tabs.init();
    fhPublishRefreshOnChange.init();
});

var bootstrapPlugins = {
    init: function() {
        require.ensure([], function(require) {
            require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
            require('bootstrap-sass/assets/javascripts/bootstrap/alert');
            require('bootstrap-sass/assets/javascripts/bootstrap/modal');
            require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');
        }, 'bootstrapPlugins');
    }
};

var tabs = {
    init: function() {
        var $tabs = $('.tabs');

        if($tabs.length) {
            require.ensure([], function(require) {
                require('bootstrap-sass/assets/javascripts/bootstrap/tab');
            }, 'bootstrapTabs');

            var $tabsContent = $tabs.find('.tab-pane'),
                $tabsNav = $tabs.find('[data-toggle=tab]');

            $tabsContent.each(function(index) {
                if($(this).find('.has-error').length) {
                    $tabsNav.eq(index).addClass('has-error').find('.icon').removeClass('hide');
                }
            });
        }
    }
};

var iframes = {
    setup: function() {
        iframes.init();

        $(document).on('ajaxComplete', function() {
            iframes.init();
        });
    },

    init: function() {
        iframes.setIframes();
    },

    setIframes: function() {
        $('iframe').not('.iframe-initialized').each(function(e) {
            var $iframe = $(this),
                width = parseInt($iframe.attr('width')),
                height = parseInt($iframe.attr('height'));

            if(width && height && width != 100) {
                var ratio = height / width;

                $iframe
                    .attr('width', '100%')
                    .attr('height', '100%')
                    .css('position', 'absolute')
                    .wrap('<div style="position: relative; padding: 0 0 ' + (100 * ratio) + '% 0;">');
            }

            $iframe.addClass('iframe-initialized');
        });
    }
};

var select2 = {
    init: function() {
        var $select2 = $('input.select2, select.select2');

        if ($select2.length > 0) {
            require.ensure([], function(require) {
                require('select2');
                require('../../../../node_modules/select2/dist/css/select2.css');

                $select2.not('.select2-initialized').each(function () {
                    var $el = $(this),
                        elData = $el.data(),
                        options = elData;

                    if (elData.select2Autocompleter) {
                        options = $.extend(elData, {
                            multiple: elData.select2Multiple,
                            minimumInputLength: elData.select2MinimumInputLength,
                            initSelection: function (element, callback) {
                                var value = element.val();
                                if (value !== '') {
                                    $.ajax({
                                        url: element.data('select2-remote') + '/init',
                                        dataType: 'json',
                                        data: {
                                            autocompleter: element.data('select2-autocompleter'),
                                            identifiers: value
                                        },
                                        success: function (data) {
                                            callback(elData.select2Multiple ? data.items : data.items[0]);
                                        }
                                    });
                                }
                            },
                            ajax: {
                                url: elData.select2Remote + '/search',
                                dataType: 'json',
                                data: function (term, page) {
                                    return {
                                        autocompleter: elData.select2Autocompleter,
                                        q: term
                                    };
                                },
                                results: function (data, page) {
                                    return {results: data.items};
                                }
                            }
                        });
                    } else {
                        $el.select();
                    }

                    $el
                        .addClass('select2-initialized')
                        .select2(options);
                });
            }, 'select2');
        }
    }
};

var twitter = {
    init: function() {
        $(document).on('ajaxComplete', function() {
            if(typeof twttr != 'undefined') {
                twttr.widgets.load();
            };
        });
    }
};

var links = {
    options: {
        toggle: true
    },

	init: function() {
    	var _this = this;

    	if(_this.options.toggle) {
    		_this.setToggle();
    	}
	},

	setToggle: function() {
    	var _this = this;

	    $('.js-toggle').on('click', function(e) {
	    	e.preventDefault();

	    	var $el = $(this),
	    		$target = $($el.data('target'));

	    	$target.toggleClass('is-active');
	    });
	}
};

var fhConfirm = {
    selector: '.btn-confirm',

    init: function()
    {
        var _this = this;

        if ($(this.selector).length > 0) {
            require.ensure([], function(require) {
                require('fhConfirm');

                _this.setConfirmMessages();

                $(document).on('ajaxComplete', function() {
                    _this.setConfirmMessages();
                });
            }, 'fhConfirm');
        }
    },

    setConfirmMessages: function() {
        var _this = this;

        $(_this.selector).not('.fhConfirm-initialized').each(function() {
            var $el = $(this),
                options = $.extend({}, $el.data());

            $el.fhConfirm(options).addClass('fhConfirm-initialized');
        });
    }
};

var sort = {
    init: function() {
        var _this = this;
        _this.setSortable();
    },

    setSortable: function() {
        var $sortable = $('.js-sortable');

        if ($sortable.length) {
            var Sortable = require('sortablejs');

            $sortable.each(function () {
                var $el = $(this),
                    data = $el.data();

                Sortable.create(this, {
                    connectWith: '.js-sortable-item',
                    placeholder: 'sortable-item-placeholder',
                    onEnd: function(event) {
                        var idx = [];
                        $el
                            .find('.js-sortable-item')
                            .each(function(k, el){
                                var id = $(el).data('id');
                                if (id != null) {
                                    idx.push(id);
                                }
                            });

                        $.ajax({
                            url: data.sortableUrl,
                            data: {
                                sortedIds: idx
                            },
                            success: function (response) {
                                $('<div class="alert alert-success"><p>' + data.message + '</p></div>')
                                    .appendTo($el.data('alert-holder') ? $el.data('alert-holder') : '.js-alert-holder')
                                    .delay(1000)
                                    .fadeOut(function() {
                                        $(this).remove();
                                    });
                            }
                        });
                    }
                });
            })
        }
    }
};

var tables = {
    options: {
        batchSelect: true,
        deleteAction: true
    },

  	init: function() {
      	var _this = this;

      	if(_this.options.batchSelect) {
      		_this.setBatchSelect();
      	}

      	if(_this.options.deleteAction) {
      		_this.setDeleteAction();
      	}
  	},

  	setDeleteAction: function() {
    	  $('.btn-delete-row').on('click', function(e) {
      	    e.preventDefault();

      	    var $el = $(this),
      	        elData = $el.data();

      	    $.ajax({
        	    url: elData.url,
        	    method: elData.method,
        	    data: {
          	      _sonata_csrf_token: elData.csrfToken
        	    },
        	    success: function() {
          	      $el.closest('tr').fadeOut(function() {
              	      $(this).remove();
          	      });
        	    }
      	    });
    	  });
  	},

  	setBatchSelect: function() {
  		$('.js-batch-select').each(function() {
  			var $el = $(this),
  				$children = $el.closest($el.data('target')).find(':checkbox').not(this);

  			$el.on('change', function() {
  				$children.prop('checked', $el.is(':checked'));
  			});

  			$children.on('change', function() {
  				if(!$(this).prop('checked')) {
  					$el.prop('checked', false);
  				};
  			});
  		});
  	}
};

// Blocks javascript

var blocks = {
    blocks: null,

    init: function() {
        var _this = this;

        _this.setBlocks();

        _this.setAddBlocks();
        _this.setBlockForms();
        _this.setSortBlocks();
        _this.setDeleteBlocks();
    },

    setBlocks: function() {
        var _this = this;

        _this.blocks = $('.js-block');

        if(_this.blocks.length) {
            $('.js-template-selector').fadeOut();
        } else {
            $('.js-template-selector').fadeIn();
        }
    },

    setAddBlocks: function() {
        var _this = this;

        $('.blocks').on('click', '.js-show-blocks', function(e) {
            e.preventDefault();

            var $el = $(this),
                elData = $el.data();

            var position = $('.js-show-blocks').index($el);

            blocks.insertPosition = position;
        });

        $('.js-select-block').on('click', function(e) {
            e.preventDefault();

            var $el = $(this),
                elData = $el.data();

            // create new block at given position
            $.ajax({
                url: $el.attr('href'),
                method: 'post',
                data: {position: blocks.insertPosition + 1},
                success: function(response) {
                    var $block = $('<li class="blocks-item">' + response + '</li>').addClass('is-updated');
                    $('.js-show-blocks').eq(blocks.insertPosition).closest('li').after($block);
                    $('.js-blocks-gallery').modal('hide');
                    _this.resetBlocks(true);

                    // update the positions of other blocks
                    _this.batchUpdateBlockPositions();

                    $block.closest('.blocks').trigger('block:after_add', $block);

                    $('html, body').animate({ scrollTop: $block.offset().top - 100 }, 500);
                },
                error: function(e) {

                }
            });
        });
    },

    resetBlocks: function(includeForms) {
        var _this = this;

        _this.setBlocks();
        _this.setSortBlocks();
        _this.setBlockForms();

        if(includeForms) {
          select2.init();
          forms.init();
        }
    },

    batchUpdateBlockPositions: function() {

        // get url for ajax request to batch update
        var $sortForm = $('.js-sort-form');

        if (!$sortForm.length > 0) {
            return;
        }

        var positions = this.getBlockPositions();
        var data = $.extend(positions, {
            'action': 'position'
        });

        $.ajax({
            url: $sortForm.attr('action'),
            method: $sortForm.attr('method'),
            data: data
        });
    },

    getBlockPositions: function() {

        var positions = {
            'position': [],
            'idx': []
        };

        $.each(this.blocks, function (index, block) {
            var id = block.getAttribute('data-id');
            if (id != null) {
                positions.position.push(index + 1);
                positions.idx.push(id);
            }
        });

        return positions;
    },

    setBlockForms: function() {
        var _this = this;

        $('.js-block-form').not('.block-form-initialized')
            .on('submit', function(e) {
                e.preventDefault();

                var $el = $(this),
                    $form = $el.closest('form'),
                    $block = $el.closest('.js-block');

                $block.addClass('is-submitting');

                $.ajax({
                    url: $el.attr('action'),
                    method: $el.attr('method'),
                    data: $form.serialize(),
                    success: function(response) {
                        $response = $(response);
                        $block.replaceWith($response);

                        $block.removeClass('is-submitting');
                        $response.addClass('is-updated');
                        _this.resetBlocks(true);

                        var $blocksContainer = $response.closest('.blocks');
                        $blocksContainer.trigger('block:after_update', $response);
                    },
                    error: function(response) {
                        console.log(response);
                    }
                });
            })
            .addClass('block-form-initialized');
    },

    setDeleteBlocks: function() {
        var _this = this;

        $('.blocks').on('click', '.js-delete-block', function(e) {
            e.preventDefault();

            var $el = $(this),
                elData = $el.data(),
                $block = $($el.closest(elData.target));

            $block.addClass('is-submitting');

            $.ajax({
                url: elData.url,
                method: elData.method,
                success: function() {
                    $block.slideUp(function() {
                        var $blocksContainer = $block.closest('.blocks');
                        $block.closest('li').remove();

                        $blocksContainer.trigger('block:after_delete', $block);

                        _this.resetBlocks();
                    })
                }
            });
        });
    },

    setSortBlocks: function() {
        var _this = this;

        if ($('.js-sorting-blocks').length > 0) {
            var Sortable = require('sortablejs');
        }

        _this.blocks.each(function() {
            var $block = $(this),
                blockData = $block.data(),
                $currentBlock = $('.js-sorting-blocks-current'),
                $sortingList = $block.find('.js-sorting-blocks'),
                height = $currentBlock.outerHeight(),
                currentPosition = 0;

            $sortingList.empty();

            var typeCount = {};

            _this.blocks.each(function(index) {
                var data = $(this).data();

                if(data.id == blockData.id) {
                    currentPosition = index;
                }

                typeCount[data.type] = typeCount[data.type] ? typeCount[data.type] + 1 : 1;

                $sortingList.append('<li data-id="' + data.id + '" class="js-sort-block ' + (data.id == blockData.id ? 'is-current' : '') + '"><i class="icon icon-sort"></i> <i class="icon ' + data.icon + '"></i> ' + data.type + ' (' + typeCount[data.type] + ')</li>');
            });

            $sortingList.css('top', 0 - (height * currentPosition));
            $sortingList.parent()
                .css('height', height)
                .css('width', $sortingList.width() + 50);

            if($sortingList.data('sortable')) {
                $sortingList.data('sortable').destroy();
            }

            var sort = Sortable.create($sortingList.get(0), {
              animation: 150,
              draggable: ".js-sort-block",
              onStart: function() {
                  $sortingList.parent().addClass('is-sorting');
                  $('body').addClass('is-block-sorting');
              },
              onEnd: function(e) {
                  $sortingList.parent().removeClass('is-sorting');
                  $('body').removeClass('is-block-sorting');

                  if(typeof e.newIndex != 'undefined') {

                      var $form = $('.js-sort-form');

                      var data = {
                          'action': 'position',
                          'position': [],
                          'idx': []
                      }

                      $sortingList.find('li').each(function(index) {
                          data.position.push(index + 1);
                          data.idx.push($(this).data('id'));
                      });

                      $.ajax({
                          url: $form.attr('action'),
                          method: $form.attr('method'),
                          data: data
                      });

                      _this.sortBlocks(e.oldIndex, e.newIndex);
                  }
              }
            });
            $sortingList.data('sortable', sort)
        });
    },

    sortBlocks: function(oldIndex, newIndex) {
        var _this = this;

        var $oldBlock = _this.blocks.eq(oldIndex).closest('li');
        var $replacementBlock = _this.blocks.eq(newIndex).closest('li');


        if(newIndex == 0) {
            $replacementBlock.before($oldBlock);
        } else {
            $replacementBlock.after($oldBlock);
        }

        $('html, body').animate({
            scrollTop: $oldBlock.offset().top - 100
        }, 500);
        $oldBlock.addClass('is-updated');

        _this.resetBlocks(true);
    }
};


var datePicker = {
    selector: '.picker__input, .datepicker, .timepicker',
    initializedClass: 'datepicker-initialized',
    dateFormat: 'dd-mm-yyyy',
    submitFormat: 'yyyy-mm-dd',
    timeFormat: 'HH:i',

    init: function() {
        var _this = this;

        if ($(_this.selector).length > 0) {
            require.ensure([], function(require) {
                require('pickadate/lib/picker');
                require('pickadate/lib/picker.date');
                require('pickadate/lib/picker.time');
                require('../../../../node_modules/pickadate/lib/themes/default.css');
                require('../../../../node_modules/pickadate/lib/themes/default.date.css');
                require('../../../../node_modules/pickadate/lib/themes/default.time.css');

                _this.setDateTimePickers();
            }, 'pickadate');
        }
    },

    setDateTimePickers: function() {
        var _this = this;

        var $dateTimePickers = $(_this.selector).not('.' + _this.initializedClass),
            $datePickers = $dateTimePickers.not('[type=time]'),
            $timePickers = $dateTimePickers.filter('[type=time]'),
            dateRanges = false;

        $timePickers.each(function() {
            var $el = $(this),
                elData = $el.data();

            var options = $.extend({
                format: _this.timeFormat
            }, elData);

            $el.pickatime(options);
        });

        $datePickers.each(function() {
            var $el = $(this),
                elData = $el.data();

            var options = $.extend({
                format: _this.dateFormat,
                formatSubmit: _this.submitFormat
            }, elData);

            if ( elData.fromRange || elData.toRange ) {
                dateRanges = true;
            }

            $el.pickadate(options);
        });

        if ( dateRanges ) {
            // Loop through $datePickers again to set DateRanges
            $datePickers.each(function() {
                var $el = $(this),
                    elData = $el.data();

                if ( elData.fromRange ) {
                    var $toPicker = $datePickers.filter('[data-to-range=' + elData.fromRange + ']');

                    if ( !$toPicker.length ) {
                        return;
                    }

                    var fromPicker = $el.pickadate('picker'),
                        toPicker = $toPicker.pickadate('picker');

                    if ( fromPicker.get('value') ) {
                        toPicker.set('min', fromPicker.get('select'));
                    }
                    if ( toPicker.get('value') ) {
                        fromPicker.set('max', toPicker.get('select'));
                    }

                    fromPicker.on('set', function(event) {
                        if ( event.select ) {
                            toPicker.set('min', fromPicker.get('select'));
                        } else if ( 'clear' in event ) {
                            toPicker.set('min', false);
                        }
                    });

                    toPicker.on('set', function(event) {
                        if ( event.select ) {
                            fromPicker.set('max', toPicker.get('select'));
                        } else if ( 'clear' in event ) {
                            fromPicker.set('max', false);
                        }
                    });
                }
            });
        }

        $dateTimePickers.addClass(_this.initializedClass);
    }
};

// Forms javascript

var forms = {
    options: {
        datepicker: true,
        redactor: true,
        select2: false
    },

    init: function() {
        var _this = this;

        if(_this.options.datepicker) {
            datePicker.init();
        }
        if(_this.options.redactor) {
            redactor.init();
        }
        if(_this.options.select2) {
            select2.init();
        }
        _this.setCrop();
        _this.setCropTriggers();
        _this.setFileupload();
    },


    // Crop javascript

    setCropTriggers: function() {
        var _this = this;

        if(!_this.cropTriggersSet) {
            _this.cropTriggersSet = true;

            $(document).on('click', '.js-crop-trigger', function(e) {
                e.preventDefault();

                var $el = $(this),
                    elData = $el.data(),
                    $target = $(elData.target),
                    $img = $target.find('.crop-image'),
                    imgData = $img.data(),
                    options = $.extend({}, imgData.cropConfig);

                $target.addClass('is-active');

                if(elData.original) {
                    $img.attr('src', imgData.dir + '/' + elData.original);
                }

                if(imgData.cropSet) {
                    $img.cropper('destroy');
                }

                $img.cropper(options);
                imgData.cropSet = true;
            });
        }
    },

    setCrop: function() {
        var $crop = $('.js-crop');

        if ($crop.length > 0) {
            require.ensure([], function (require) {
                require('cropper');
                require('cropper/dist/cropper.css');
            });
        }

        $crop.not('.crop-initialized').each(function() {
            var $el = $(this),
                elData = $el.data(),
                $img = $el.find('img');

            $el.find('.js-cancel-crop').on('click', function(e) {
                e.preventDefault();

                $el.removeClass('is-active');
            });

            $el.find('.js-crop-image').on('click', function(e) {
                e.preventDefault();
                forms.cropImage($img, $el);
            });

            $el.addClass('crop-initialized');
        });
    },

    cropImage: function($img, $el) {
        var _this = this,
            data = $img.cropper('getData', true),
            imgData = $img.data(),
            c = {
                w: data.width,
                h: data.height,
                x: data.x,
                x2: data.x + data.width,
                y: data.y,
                y2: data.y + data.height
            };

        $.ajax(imgData.realtimeCropUrl, {
            method: 'POST',
            data: { crop: c, image: $img.attr('src').replace(/.*\//, '') },
            success: function(response){},
            error: function(response){}
        }).done(function()
        {
            $el.removeClass('is-active');
            var $croppedImage = $el.next('.js-file-upload').find('img');
            $croppedImage.attr('src', $croppedImage.attr('src').replace(/\?.*/, '') + '?' + (new Date()).getTime())
        });
    },

    // File upload javascript

    setFileupload: function() {
        var $fileupload = $('.js-file-upload');

        if ($fileupload.length) {
            require('blueimp-file-upload/js/jquery.fileupload');
        }

        if (! $().fileupload) {
            return;
        }
        var _this = this;

        $fileupload.not('.fileupload-initialized').each(function() {
            var $holder = $(this),
                $el = $holder.find('input[type=file]'),
                elData = $el.data(),
                $formGroup = $holder.closest('.form-group'),
                $hiddenInput = $holder.find('input[name="' + $el.data('name') + '"]').filter('input[type=hidden],input[type=text]'),
                $preview = $holder.find('.js-file-upload-preview'),
                $actions = $holder.find('.js-file-upload-actions'),
                $callToAction = $holder.find('.js-file-upload-call-to-action'),
                $uploadBtn = $holder.find('.js-file-upload-button'),
                cancelBtnClass = '.js-file-upload-cancel',
                $cancelBtn = $holder.find(cancelBtnClass),
                removeBtnClass = '.js-file-upload-remove',
                $uploadWait = $holder.find('.js-file-upload-wait');

            $el.fileupload({
                formData: {},
                dataType: 'json',
                dropZone: $holder,
                error: function(xhr) {
                    $formGroup.addClass('has-error');
                },
                done: function(e, data) {
                    if (data.result.success == false) {
                        $formGroup.addClass('has-error');
                        $holder.append('<ul class="list--error"><li>' + data.result.error + '</li></ul>');

                        return;
                    }

                    if (data.result.files.length > 0) {
                        if(elData.filesTarget) {
                            var $filesTarget = $(elData.filesTarget),
                                name = elData.name;

                            $.each(data.result.files, function (index, file) {
                                var newIndex = (new Date()).getTime(),
                                    namePrefix = name + '[new_' + newIndex + ']',
                                    $item = $('<li/>');

                                var itemContent = '<div class="row">';

                                itemContent += '<div class="col-xs-4 file-upload-list-preview">';
                                if (file.mimetype.substring(0,6) == 'image/') {
                                    itemContent += '<img src="' + file.url + '" alt="" />';
                                }

                                itemContent += '</div>';
                                itemContent += '<div class="col-xs-8">';

                                itemContent += '<input type="hidden" name="' + namePrefix + '[file]" value="' + file.name + '"/>';
                                itemContent += '<input type="text" class="form-control" name="' + namePrefix + '[title]" value="" placeholder="' + file.original_name + '"/>';

                                itemContent += '<br/><a href="#" class="btn btn-default js-file-upload-remove"><i class="icon icon-trash"></i> Verwijderen</a>';

                                itemContent += '</div>';

                                $item.append(itemContent);
                                $item.appendTo($filesTarget);
                            });
                        } else {
                            var file = data.result.files[0];

                            $actions.removeClass('is-hidden');
                            $cancelBtn.removeClass('is-hidden');
                            $callToAction.addClass('is-hidden');

                            $preview.html('');
                            $hiddenInput.val(file.name);

                            if ($preview.is('.is-image')) {
                                var $img = $('<img />').attr('src', file.url);
                                $preview.prepend($img);

                                var $cropTrigger = $holder.find('.js-crop-trigger');

                                $cropTrigger.data('original', data.result.files[0].name);
                            } else {
                                var $link = $('<a class="file-upload-doc"></a>').text(file.name).attr('href', file.url).attr('target', '_blank');
                                $preview.prepend($link);
                            }
                        }
                    }
                },
                start: function(e) {
                    $cancelBtn.addClass('is-hidden');
                    $uploadBtn.addClass('is-hidden');
                    $uploadWait.removeClass('is-hidden');

                    // Remove any error classes
                    $formGroup.removeClass('has-error').find('.list--error, .list-error').remove();
                },
                stop: function(e) {
                    $uploadBtn.removeClass('is-hidden');
                    $uploadWait.addClass('is-hidden');
                }
            }).attr('name', 'files[]');

            $holder
                .on('click', cancelBtnClass, function(e) {
                    e.preventDefault();

                    $hiddenInput.val('');
                    $preview.html('');
                    $cancelBtn.addClass('is-hidden');
                    $actions.addClass('is-hidden');
                    $callToAction.removeClass('is-hidden');
                })
                .on('click', removeBtnClass, function(e) {
                    e.preventDefault();

                    $(this).closest('li').slideUp(function() {
                        $(this).remove();
                    });
                });



            $('.fileupload__list').on('click', 'a.remove', function(e) {
                e.preventDefault();
                $(this).closest('li').remove();
            });

            $holder.addClass('fileupload-initialized');
        });

        if(!_this.dragOverTriggered) {
            _this.dragOverTriggered = true;

            $(document).bind('dragover', function (e) {

                var $dropZones = $('.js-file-upload'),
                    timeout = window.dropZoneTimeout;

                $dropZones.addClass('is-dropzone');

                if (timeout) {
                    clearTimeout(timeout);
                }
                var found = false,
                node = e.target;

                do {
                    if ($(node).hasClass('js-file-upload')) {
                        found = true;
                        foundDropzone = $(node);
                        break;
                    }

                    node = node.parentNode;

                } while (node != null);

                $dropZones.removeClass('is-hovering');

                if (found) {
                    foundDropzone.addClass('is-hovering');
                }

                window.dropZoneTimeout = setTimeout(function ()
                {
                    window.dropZoneTimeout = null;
                    $dropZones.removeClass('is-dropzone is-hovering');
                }, 100);
            });
        }
    }
};




// Publish

var fhPublishBlockRelated = {
    init: function() {
        $('.blocks').on('click', '.js-related-post', function(e) {
            e.preventDefault();

            var $el = $(this);
            var $block = $el.closest('.block');

            var $target = $block.find($el.data('target'));
            $target.val($el.val()).trigger('change');
        });
        $('.blocks').on('change', '.block--related input, .block--related select', function(e) {
            var $el = $(this);
            var $form = $el.closest('form');

            $form.trigger('submit');
        });
    }
};

var fhPublishRefreshOnChange = {
    init: function(){
        $('select[data-refresh-target]').change(function() {
            var $el = $(this);
            var refreshRemotes = $el.data('refresh-remote'),
                refreshTargets = $el.data('refresh-target');

            if (! $.isArray(refreshRemotes)) {
                refreshRemotes = [refreshRemotes];
            }

            if (! $.isArray(refreshTargets)) {
                refreshTargets = [refreshTargets];
            }

            $.each(refreshRemotes, function (index, refreshRemote) {
                var $refreshTarget = $(refreshTargets[index]);

                $refreshTarget.prop('disabled', true);

                $.ajax({
                    url: refreshRemote,
                    type: 'POST',
                    dataType: 'json',
                    data: { value: $el.val() }
                }).success(function(response) {
                    $refreshTarget.prop('disabled', false);
                    $refreshTarget.empty();
                    $refreshTarget.append(
                        $('<option></option>')
                            .attr('value', '')
                            .text(response.empty_value)
                    );
                    $.each(response.options, function(key, value) {
                        $refreshTarget.append(
                            $('<option></option>')
                                .attr('value', value['id'])
                                .text(value['name'])
                        );
                    });
                });
            });
        });
    }
};

var fhPublishTextCounter = {
    selector: '.js-counter',

    setup: function() {
        fhPublishTextCounter.init();

        $(document).ajaxComplete(function() {
            fhPublishTextCounter.init();
        });
    },

    init: function()
    {
        if (!$(fhPublishTextCounter.selector).length) {
            return;
        }

        var Countable = require('countable/Countable.min.js');

        $(fhPublishTextCounter.selector).not('.textcounter-initialized').each(function(e) {
            var $el = $(this),
                $counter = $el.parent().find('.js-textcounter-text'),
                maxChars = $el.data('max-chars');

            Countable.on($el.get(0), function(counter) {
                var charsCount = counter.all;

                $counter.text(charsCount).toggleClass('is-over', charsCount > maxChars);
            });

        }).addClass('textcounter-initialized');
    }
};

$(document).ready(function() {
    fhPublishBlockRelated.init();
    fhPublishTextCounter.setup();
});

module.exports = {
    forms: forms,
    select2: select2,
    redactor: redactor,
    datePicker: datePicker,
    blocks: blocks,
    fhConfirm: fhConfirm,
    tables: tables,
    sort: sort,
    links: links
};
