var $ = require('jquery');
window.jQuery = $;
require('../fhadmin/src/js/index');

// Modules
var datepicker = require('./modules/app/datepicker');

$(document).ready(function() {
    datepicker.init();
});
